import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RegisterService } from "../services/register/register.service";
import { CookieService } from 'ngx-cookie-service';
import { HttpClient,HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { UserService } from '../services/user/user.service';
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  currentbtn = true;
  forgetpasswordmobile: FormGroup;
  forget: any;
  frorget:any=true;
  sucessmessage:any=false;
  UniqueNumber:any
  arr1:any=[]
  uniqueKey:any;
  constructor(
    private route11: Router,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private cookieService: CookieService,
    private http:HttpClient,
    private userService: UserService, 
    private route: ActivatedRoute,
  ) { 
    
  }

  ngOnInit(): void {

    let url:any=this.route11.url;
    console.log(url);
   
    this.route.snapshot.url.forEach(segment => {    
      // Logs each segment of the URL 
     
         this.arr1.push(segment.path)
 
      });
      this.uniqueKey=this.arr1[2];
      console.log('uniqueKey',this.uniqueKey)
    this.forgetpasswordmobile = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword:['', Validators.required],
      UniqueNumber:this.uniqueKey
    });
  }
  forgetnewpassword(){
    if (this.forgetpasswordmobile.invalid) {
      return;
    }
    let params = new HttpParams();
    params = params.append('password', this.forgetpasswordmobile.value.password);
    params = params.append('confirmpassword', this.forgetpasswordmobile.value.confirmpassword);
    params = params.append('UniqueNumber', this.forgetpasswordmobile.value.UniqueNumber);

    debugger
      //    this.registerService
      //   .forgetpassword(params)
      //  .subscribe((res) => {
      //    console.log(res);
      //   });
      this.http.get(environment.serviceUrl+'account/VerifyChangepasswordLink?'+params).subscribe((res:any)=>{
  

        if (res.success == true) {
          this.frorget=false;
        this.forget = res.data;
        this.userService.showNotification('success', res.message);
        this.currentbtn = true;
        this.forgetpasswordmobile.reset();
 
        this.sucessmessage=true;
        }
        else{
          this.userService.showNotification('danger', res.message);
        }
      
      })
 }



  onchangebtn(e) {
    console.log(e.value.email);
    this.currentbtn = false;
  }
}
